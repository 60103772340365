.myaccount {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.accountpay {
  width: 100%;
  display: flex;
  margin-bottom: 30px;
}
.account {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
  margin-top: 20px;

  &__container {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    min-height: 270px;
    align-content: space-around;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
  }
  &__card {
    display: flex;
    height: auto;
    width: 100%;
    justify-content: flex-start;
    margin-left: 2rem;
    gap: 1rem;
  }
  &__description {
    width: max-content;
  }
  &__avatar {
    width: 4.375rem;
    height: 4.375rem;
    justify-content: flex-end;
    display: flex;
    border-radius: 50%;
    box-shadow: 0 0 0px 1px $high-smartman-color;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  &__labels {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
  }
  &__title-label {
    min-width: 115px;
    display: inline-block;
    margin: 12px 0;
  }
  &__status {
    line-height: 24px;
    color: $contrast-color;
    display: inline-flex;
    width: 6.125rem;
    justify-content: space-between;
    align-items: center;
    align-content: space-around;
  }
  &__status-tooltip {
    display: inline-flex;
    background: url("./../../images/tooltip/blue-help.svg") no-repeat 100% 50%;
    background-size: 25px 25px;
    cursor: pointer;
    width: 25px;
    height: 25px;
  }

  &__status:nth-child(1) {
    margin-right: 30px;
  }
  &__warning {
    width: 100%;
    display: grid;
    grid-template-columns: max-content max-content;
    // justify-content: center;
    gap: 0.5rem;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $contrast-color;
    text-align: center;
  }
  &__button {
    border: 1px solid $contrast-color;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    background: $contrast-color;
    color: white;
    height: auto;
    padding: 5px 30px;
    max-width: 240px;
    margin: 1rem;
    opacity: 0;
    cursor: pointer;
    animation: fadeIn .25s linear forwards;
  }
}
.methodpay {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
  margin-top: 20px;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
  }
  &__subtitle {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    height: 50px;
    align-items: center;
  }
  &__card {
    background-color: $cards-color;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 140px;
    width: 95%;
  }
  &__content {
    width: 90%;
    display: flex;
    height: 50px;
    align-items: center;
  }
  &__visa {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__logo {
    margin-right: 15px;
  }
  &__history {
    width: 50%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.168px;
    color: $contrast-color;
  }

  &__update {
    border: 1px solid $contrast-color;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $contrast-color;
    height: 34px;
    width: 40%;
    margin-bottom: 20px;
    cursor: pointer;
  }
}
.methodpay {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 170px;
  margin-top: 20px;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin-left: 20px;
  }
  &__subtitle {
    width: 90%;
    display: flex;
    justify-content: flex-start;
    height: 50px;
    align-items: center;
  }
  &__card {
    background-color: $cards-color;
    box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 140px;
    width: 95%;
  }
  &__content {
    width: 90%;
    display: flex;
    height: 50px;
    align-items: center;
  }
  &__visa {
    width: 50%;
    display: flex;
    align-items: center;
  }
  &__logo {
    margin-right: 15px;
  }
  &__history {
    width: 50%;
    padding-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    letter-spacing: 0.168px;
    color: $contrast-color;
  }

  &__update {
    border: 1px solid $contrast-color;
    border-radius: 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $contrast-color;
    height: 34px;
    width: 40%;
    margin-bottom: 20px;
    cursor: pointer;
  }
}

.plansection {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  padding: 1rem;
  gap: 1rem;
  background-color: $cards-color;
  &.payment {
    background-color: transparent;
    display: grid;
    grid-template: max-content 1fr / 1fr;
    .c-plan {
      gap: 0.5rem;
      min-height: 20.3125rem;
    }
    .plansection__plans {
      align-items: flex-start;
    }
  }
  &__title {
    display: flex;
    align-items: center;
    font-style: normal;
    border-radius: 8px;
    font-weight: 600;
    padding-left: 1rem;
    height: 3.1875rem;
    font-size: 1.5625rem;
    line-height: 24px;
    color: $main-text-color;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.211765);
  }
  &__toggle {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__plans {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
    gap: 1.1rem;
  }
}
.c-plan {
  width: 100%;
  max-width: 21.875rem;
  background-color: $cards-color;
  display: grid;
  grid-template: max-content 1fr max-content/1fr;
  justify-items: center;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.211765);
  border-radius: 8px;
  padding: 1rem 0;
  min-height: 715px;
  &_text {
    font-weight: normal;
    font-size: 0.8125rem;
    color: $gray-90;
    margin: 0;
  }
  &_header {
    margin-bottom: 1.25rem;
    &-title {
      font-style: normal;
      font-weight: 600;
      font-size: 1.125rem;
      line-height: 27px;
      text-align: center;
      color: $main-text-color;
      margin: 0;
    }
    &-cost {
      color: $contrast-color;
      text-align: center;
      font-size: 3.125rem;
      font-weight: 700;
      margin: 0;
      small {
        font-size: 1rem;
      }
    }
    &-users {
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 0.8125rem;
      margin: 0;
      color: $main-text-color;
    }
    .c-plan_text {
      text-align: center;
      &.enterprice {
        font-weight: 500;
        font-size: 1.5625rem;
        line-height: 2rem;
        text-align: center;
        margin-top: 1.25rem;
        color: $contrast-color;
      }
    }
  }
  &_sales,
  &_order,
  &_ai,
  &_aditionals {
    width: 100%;
  }
  &_title {
    font-size: 1rem;
    font-weight: normal;
    padding: 0 1rem;
    margin: 0;
  }

  &_list {
    list-style: none;
    padding: 0 0 0 2rem;
    &-item {
      font-size: 0.8125rem;
      position: relative;
      font-weight: lighter;
      color: $gray-90;
      &::before {
        content: url("../../images/myaccount/icon_check.svg");
        position: absolute;
        left: -1.0625rem;
      }
      &.is-inactive {
        color: $gray-50;
        &::before {
          content: url("../../images/myaccount/icon_check-disabled.svg");
        }
      }
    }
  }

  &__type {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    color: var(--main-text-color);
  }

  &_button {
    border-radius: 27px;
    background-color: $contrast-color;
    color: $cards-color;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    width: 60%;
    padding: 10px 0;
    display: flex;
    cursor: pointer;
    justify-content: center;
    &.is-selected {
      user-select: none;
      pointer-events: none;
      cursor: initial;
      border: 1px solid $gray-30;
      border-radius: 27px;
      background-color: $cards-color;
      color: $gray-90;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      width: 60%;
      padding: 10px 0;
      display: flex;
      justify-content: center;
    }
  }
  .c-accordion {
    box-shadow: none;
    margin: 0;
    &::before {
      display: none;
    }
    &_title {
      min-height: 10px;
      .MuiAccordionSummary-content > * {
        margin: 0;
        padding: 0;
      }
      .MuiAccordionSummary-expandIcon {
        padding: 3px;
        color: $contrast-color;
      }
      &[aria-expanded="true"] {
        color: $contrast-color;
      }
    }
    &_content {
      padding: 8px 0 16px;
    }
  }
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }

}