.magicalordering {
  border-radius: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  &[data-plan="FREE"] {
    &::after {
      position: absolute;
      top: 0;
      content: attr(data-text);
      width: 1010px;
      height: 500px;
      display: flex;
      left: 0;
      justify-content: center;
      align-items: center;
      font-size: 6rem;
      opacity: 0.2;
      text-transform: uppercase;
      transform: translate(31%, 63%) rotate(-50deg);
      transform-origin: center;
      pointer-events: none;
      user-select: none;
    }
  }
  &__title {
    font-size: 1.5625rem;
    color: $contrast-color;
    font-weight: 500;
  }
  &__alert {
    grid-area: 4 / 1 / span 1 / span 1;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    color: $fail-color;
    font-size: 0.625rem;
    &::before {
      content: url(../../images/magicalordeing/icon_alert.svg);
      display: block;
      width: 13px;
      height: 13px;
    }
    &.is-inactive {
      color: transparent;
      &::before {
        display: none;
      }
    }
  }
}

.stepbystep {
  width: 92%;
  position: relative;
  height: 60px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0 0;
  &__zoom {
    position: absolute;
    cursor: pointer;
    top: 0;
    right: 0;
    width: 45px;
    height: 45px;
    border-radius: 10px;
    background: $contrast-color;
    display: flex;
    justify-content: center;
    align-items: center;
    & * {
      pointer-events: none;
    }
  }
}

.step {
  display: flex;
  align-items: center;

  &__num {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $gray-50;
    border: 2.5px solid $gray-50;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &__num-actual {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $contrast-color;
    border: 2.5px solid $contrast-color;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &__num-done {
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: $cards-color;
    background-color: $contrast-color;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }
  &__way {
    width: 80px;
    height: 2px;
    background-color: $gray-50;
  }
  &__way-done {
    width: 80px;
    height: 2px;
    background-color: $contrast-color;
  }
}

.contentsteps {
  width: 92%;
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  align-items: center;
  background-color: $cards-color;

  border-radius: 0 0 10px 10px;
}

.stepsbuttons {
  width: 43rem;
  height: 20%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 30px;

  &__back {
    border: 1px solid $gray-50;
    border-radius: 30px;
    width: 25%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $gray-90;
    padding: 10px 0;
    cursor: pointer;
  }
  &__continue {
    background: $gray-30;
    border-radius: 30px;
    width: 25%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $gray-90;
    padding: 10px 0;
  }
  &__continue-active {
    background-color: $contrast-color;
    border-radius: 30px;
    width: 25%;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $cards-color;
    padding: 10px 0;
    cursor: pointer;
    &:hover{
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    }
  }
  &__save {
    background: $gray-30;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $gray-90;
    padding: 10px 20px;
  }
  &__save-active {
    background-color: $contrast-color;
    border-radius: 30px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: $cards-color;
    padding: 10px 20px;
  }
}
.stepstitle {
  width: 100%;
  height: 10.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 1rem 0 1.25rem;

  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    color: $main-text-color;
  }

  &__line {
    border: 1px solid $gray-30;
    margin: 10px 0;
  }
  &__steps {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: auto;
    background: #f5f6fa;
    padding: 1rem 2rem;
    margin: auto;
  }
}
.stepone {
  width: 80%;
  display: grid;
  grid-template: 1fr 1fr / 30rem;
  justify-content: center;
  gap: 1rem;
  grid-auto-rows: max-content;
  &__subtitle {
    grid-area: 1 / 1 / span 1 / span 2;
    font-style: normal;
    font-weight: 500;
    line-height: 19px;
    color: $main-text-color;
    display: block;
    text-align: center;
    font-size: 22px;
  }
  &__subtitle2 {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: $gray-90;
  }
  &__sortbycategories{
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 20px;
    justify-items: center;
    padding-bottom: 20px;

  }
  &__cardformadeordenamiento{
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 27%;
    height: 310px;
    align-self: flex-start;
    border-radius: 10px;
    padding: 40px 10px 20px 10px;
    margin-left: 15px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    max-width: 270px;
    max-height: 344px;
    display: "flex";
    font-style: normal;
    font-weight: normal;
    line-height: 18px;
    background-color: #ffffff;
    &__radiobuttons{
      width: 100%;
      // display: block;
      color: gray;
    }
    .beta{
      position: absolute;
      top:5px;
      right: 5px;
      // display: none;
    }
    &__initialscore{
      display: flex;
      padding-top: 20px;
      align-items: center;
      justify-content: space-between;
      align-self: flex-start;
      fieldset{
        border: none;
        padding: 0;
        margin: 0;
      }
    }
    
  }

}

.optionmagicalordering {
  display: flex;
  align-items: center;
  width: 100%;
  height: 61px;
  min-height: 61px;
  border-radius: 10px;
  background-color: $gray-10;
  padding-left: 10px;
  margin: 0.2rem auto 0;
  transition: border-radius 10s linear;

  &__option {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-right: 25px;
  }
  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__span {
    position: absolute;
    top: -8px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
    pointer-events: none;
  }
  &__checkbox:checked ~ &__span {
    background-color: $contrast-color;
    border: 1px solid $cards-color;
  }
  &__span:after {
    content: "";
    position: absolute;
    display: none;
  }
  &__checkbox:checked ~ &__span:after {
    display: block;
  }
  &__option &__span:after {
    left: 3.5px;
    top: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $cards-color;
  }
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__active {
    background-color: $contrast-color;
  }
  &__text-active {
    color: $cards-color;
  }
}
.steptwo {
  width: 80%;
  display: grid;
  grid-template: max-content / 40rem;
  justify-content: center;
  grid-auto-rows: max-content;
  margin-bottom: 20px;
  .optionmagicalordering__active {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    transition: border-radius 10s linear;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
    margin-bottom: 20px;
    display: block;
  }
  &__criteria {
    width: 100%;
    position: relative;
  }

}
.prox {
  display: grid;
  grid-template-columns: max-content max-content 1fr;
  align-items: center;
  width: 100%;
  height: 61px;
  border-radius: 10px;
  padding-left: 10px;
  color: $gray-90;

  &__circle {
    width: 18px;
    height: 18px;
    border-radius: 50%;
    border: 1px solid $gray-50;
    margin-right: 10px;
  }
  &__msg {
    background-color: $alert-color;
    border-radius: 5px;
    color: $cards-color;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    margin-left: 15px;
    justify-self: flex-end;
    padding: 2px 10px;
  }
}

.sortcriteria {
  grid-area: 2 / 1 / span 1 / span 1;
  width: 100%;
  background-color: $gray-10;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  position: relative;
  animation: fadeIn 0.5s ease-in-out forwards;
}
.headercriteria {
  width: 100%;
  display: flex;
  justify-content: space-around;
  background-color: #fff;
  border: 1px solid $gray-30;
  margin-bottom: 10px;
  &__header {
    width: 50%;
    display: flex;
    padding: 10px 0 10px 40px;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__header:first-child {
    border-right: 1px solid $gray-30;
  }
}
.list-options-criteria-0 {
  top: 110px;
}
.list-options-criteria-1 {
  top: 170px;
}
.list-options-criteria-2 {
  top: 230px;
}
.list-options-criteria-3 {
  top: 290px;
}
.plus-or-less-criteria-0 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 110px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}
.plus-or-less-criteria-1 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 170px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}
.plus-or-less-criteria-2 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 230px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}
.plus-or-less-criteria-3 {
  width: 46%;
  background-color: $background-basic-color;
  position: absolute;
  top: 290px;
  left: 52%;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px;
}

.stepthree {
  width: 80%;
  max-width: 40rem;
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 20px;
  &__title {
    text-align: center;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
    margin-bottom: 20px;
    display: block;
  }
  .orderinDates {
    display: grid;
    grid-template: max-content / max-content max-content;
    justify-content: center;
    gap: 1rem;
    .orderingends__text {
      grid-area: 1/1 / span 1 / span 2;
    }
  }
  &__option1 {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    .MuiSelect-root {
      border: 1px solid #c4c4c480;
      border-radius: 11px;
      padding-left: 10px;
      &:focus {
        border-radius: 11px;
      }
      &::before,
      &::after,
      &:focus::before {
        display: none;
      }
    }
    .MuiSelect-icon {
      color: #1996fc !important;
    }
    .MuiInput-underline {
      &::before,
      &::after {
        display: none;
      }
    }
  }
}
.stepfour {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $main-text-color;
    margin-bottom: 20px;
    display: block;
  }
  &__resume {
    width: 100%;
    display: flex;
    gap: 1rem;
  }
  &__save {
    width: 197px;
    border: 1px solid $contrast-color;
    border-radius: 27px;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    color: $contrast-color;
    padding: 7px 0;
    margin-bottom: 15px;
  }
}
.cardresume {
  width: 48%;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  border-radius: 10px;
  margin-bottom: 15px;
  display: flex;
  padding: 20px 0 15px 15px;
  &__content-main {
    width: 85%;
    display: flex;
    flex-direction: column;
  }
  &__content-image {
    width: 15%;
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }
  &__img {
    width: 30px;
    height: 30px;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 10px;
  }
  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 19px;
    margin-left: 20px;
  }
}

.formnamemagicalordering {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 20px;
  &__lbl {
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    color: $contrast-color;
    line-height: 19px;
    margin-left: 15px;
  }
  &__input {
    border: none;
    box-sizing: border-box;
    width: 382px;
    padding: 7px 0 7px 15px;
    border-bottom: 1px solid $gray-50;
  }
  &__p {
    margin: 0;
    margin-top: 10px;
    font-size: 12px;
    color: $fail-color;
  }
}
.listmagicalordering {
  width: 92%;
  display: flex;
  flex-direction: column;
  background-color: $cards-color;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  border-radius: 10px;
  padding: 1rem 0;
  margin-bottom: 1rem;
  &__content {
    width: 100%;
    overflow: hidden;
    max-height: 44rem;
    overflow-y: auto;
  }
  &__nofound {
    text-align: center;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: $contrast-color;
    width: 96%;
    margin-top: 15px;
  }
  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 50rem;
    width: 96%;
    overflow-y: scroll;
  }
}
.itemmagicalordering {
  width: 100%;
  // min-height: 50px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &__tooltip {
    border: none !important;
  }
  &__body {
    width: 11%;
    border-left: 1px solid $gray-30;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $background-basic-color;
    font-style: normal;
    font-weight: 300;
    font-size: 12px;
    line-height: 20px;
    padding: 0.25rem 5px;
    word-break: break-word;
    &__criteria {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      text-align: left;
      padding-right: 1rem;
      [date-order] {
        position: relative;
        width: 100%;
        &::after {
          content: "";
          position: absolute;
          width: 10px;
          height: 10px;
          display: block;
          background-color: $contrast-color;
          right: -0.9rem;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      [date-order="DESC"] {
        &::after {
          clip-path: polygon(50% 100%, 0 0, 100% 0);
        }
      }
      [date-order="ASC"] {
        &::after {
          clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
        }
      }
    }
    &:nth-child(1) {
      min-width: 101px;
    }
    &:nth-child(2) {
      min-width: 153px;
    }
    &:nth-child(3) {
      min-width: 100px;
    }
    &:nth-child(4) {
      min-width: 343px;
    }
    &:nth-child(5) {
      min-width: 101px;
    }
    &:nth-child(6) {
      min-width: 101px;
    }
    &:nth-child(7) {
      min-width: 101px;
    }
    &:nth-child(8) {
      min-width: 101px;
    }
    &:nth-child(9) {
      min-width: 101px;
    }

    &:nth-child(1) {
      display: flex;
      flex-direction: column;
      border: none;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
    &:nth-child(5) {
      width: 14%;
    }
    &:nth-child(10) {
      width: 14%;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
  }

  &__status {
    width: 32px;
    height: 32px;
    cursor: pointer;
    &.reload {
      width: 21px;
      height: 21px;
    }
    &__label {
      color: $contrast-color;
      font-size: 0.75rem;
      &[date-state] {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        &::before {
          content: "";
          width: 0.625rem;
          height: 0.625rem;
          display: block;
          border-radius: 50%;
        }
      }
      &[date-state="end"]::before {
        background-color: $fail-color;
      }
      &[date-state="active"]::before {
        background-color: $success-color;
      }
      &[date-state="inactive"]::before {
        background-color: $alert-color;
      }
    }
  }
  &__edit {
    width: 30px;
    height: 30px;
    background-image: url("../../images/configuration/edit-gray.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url("../../images/configuration/edit-blue.svg");
    }
  }
  &__pause {
    width: 24px;
    height: 24px;
    background-image: url("../../images/magicalordeing/pause.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
  &__delete {
    width: 30px;
    height: 30px;
    background-position: center;
    background-image: url("../../images/configuration/delete-gray.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    &:hover {
      background-image: url("../../images/configuration/delete-blue.svg");
    }
  }

  &__play {
    width: 30px;
    height: 30px;
    background-image: url("../../images/magicalordeing/play-gray.png");
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url("../../images/magicalordeing/play-blue.svg");
    }
  }
  &__playLightning {
    width: 30px;
    height: 30px;
    background-image: url("../../images/magicalordeing/play_ejecutar_icon.svg");
    cursor: pointer;
    background-repeat: no-repeat;
    background-position: center;
    &:hover {
      background-image: url("../../images/magicalordeing/play_ejecutar_hover_icon.svg");
    }
  }
}
.listdays {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;

  &__active {
    min-width: fit-content;
    background-color: #1996fc;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 0.5rem;
    cursor: pointer;
    color: $cards-color;
  }
  &__inactive {
    min-width: fit-content;
    background-color: $gray-30;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    margin: 0.5rem;
    cursor: pointer;
  }
}
.listnumdays {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  position: relative;

  &__select {
    width: 130px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $gray-30;
    border-radius: 10px;
    padding: 5px;
    cursor: pointer;
  }
  &__logo {
    width: 10px;
    height: 6px;
  }
  &__list {
    width: 130px;
    max-height: 80px;
    overflow-y: scroll;
    background-color: $gray-30;
    border-radius: 10px;
    position: absolute;
    bottom: -80px;
  }
  &__day-active {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: $contrast-color;
    color: $cards-color;
  }
  &__day-inactive {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.stepdone {
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $cards-color;
  border: 1px solid $success-color;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.0784314);
  border-radius: 5px;
  margin-right: 10px;
  padding: 0 20px;
}

.newmagicalordering {
  width: 92%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
  border-radius: 10px;
  background-color: $cards-color;
  margin-bottom: 25px;
  &__closeButton {
    position: absolute;
    right: 0.7rem;
    top: 0.5rem;
    cursor: pointer;
  }
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 32px;
    padding: 15px 0;
  }
  &__p {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 19px;
    width: 98%;
  }
  &__subtitle {
    font-style: normal;
    font-weight: 500;
    font-size: 1.5625rem;
    line-height: 27px;
    color: $contrast-color;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    cursor: pointer;
  }
  &__img {
    margin-left: 15px;
  }
  &__new {
    width: 262px;
    height: 48px;
    margin: 0;
    border-radius: 30px;
    background-color: $contrast-color;
    color: $cards-color;
    font-size: 12px;
    padding: 5px 1rem;
    align-self: flex-start;
    margin-bottom: 15px;
    cursor: pointer;
    margin-top: 1.25rem;
  }
  &__video {
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 3px 4px 2px #c4c4c4;
    padding: 1rem;
    width: 542px;
    margin: auto;
  }

  &__info {
    display: grid;
    align-content: center;
    justify-content: flex-start;
    &__title {
      margin-top: 0;
      font-size: 18px;
      font-weight: 600;
    }
    &__title2 {
      color: $click-color;
      font-size: 1.375rem;
      font-weight: 500;
      margin: 0;
    }

    &__list {
      padding-left: 1.5rem;
    }
    &__listItem {
      list-style: none;
      position: relative;
      &::before {
        content: url("../../images/myaccount/icon_check.svg");
        position: absolute;
        left: -1.0625rem;
      }
    }
  }
  .MuiAccordionSummary-content {
    display: grid;
    grid-template-columns: max-content 50px 1fr;
    gap: 0.5rem;
    align-items: center;
    .MuiSvgIcon-root {
      fill: $contrast-color;
      transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }
    &.Mui-expanded {
      .MuiSvgIcon-root {
        transform: rotate(180deg);
      }
    }
    &[data-show-icon="false"] {
      & ~ .MuiAccordionSummary-expandIcon {
        display: none;
      }
    }
    &[data-show-icon="true"] {
      .MuiSvgIcon-root {
        display: none;
      }
    }
  }
  .MuiAccordionSummary-expandIcon {
    color: $contrast-color;
  }
  .MuiAccordion-root {
    width: 100%;
    &.MuiPaper-elevation1 {
      box-shadow: none;
    }
    .MuiAccordionSummary-root {
      cursor: pointer;
      box-shadow: 0px 3px 10px rgba(65, 65, 65, 0.211765);
      &.Mui-expanded {
        min-height: 50px;
      }
      &[data-show-icon="false"] {
        .MuiAccordionSummary-expandIcon {
          display: none;
        }
      }
      &[data-show-icon="true"] {
        .MuiAccordionSummary-content {
          grid-template-columns: 1fr;
          .MuiSvgIcon-root {
            display: none;
          }
        }
      }
    }
    .MuiAccordionSummary-content {
      .newmagicalordering__new {
        display: flex;
        margin: 0;
        opacity: 0;
        height: 38px;
        align-items: center;
        justify-self: flex-end;
        gap: 0.5rem;
        animation: fadeIn 0.5s forwards linear;
      }
      &.Mui-expanded {
        margin: 12px 0;
      }
    }
    .MuiAccordionDetails-root {
      display: grid;
      grid-template: max-content / max-content max-content;
      gap: 2.5rem;
      justify-content: center;
      position: relative;
      padding: 1.5rem 1rem 1rem;
    }
  }
}

.titletablemagicalordeing {
  width: 96%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  &__title {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__content-search {
    width: 25%;
    background-color: $gray-10;
    border-radius: 16px;
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: space-between;
  }
  &__input {
    margin-left: 20px;
    width: 95%;
  }
  &__content-img {
    width: 35px;
    height: 30px;
    background-color: $contrast-color;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.headertablemagicalordering {
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  &__header {
    width: 11%;
    border-left: 1px solid $gray-50;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $gray-30;
    color: $gray-90;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    padding: 0 5px 0 5px;
  }
  &__header:nth-child(1) {
    min-width: 101px;
  }
  &__header:nth-child(2) {
    min-width: 153px;
  }
  &__header:nth-child(3) {
    min-width: 100px;
  }
  &__header:nth-child(4) {
    min-width: 343px;
  }
  &__header:nth-child(5) {
    min-width: 101px;
  }
  &__header:nth-child(6) {
    min-width: 101px;
    background-color: $contrast-color;
    color: #fff;
  }
  &__header:nth-child(7) {
    min-width: 101px;
    background-color: $contrast-color;
    color: #fff;
  }
  &__header:nth-child(8) {
    min-width: 101px;
  }
  &__header:nth-child(9) {
    min-width: 101px;
  }
  &__header:nth-child(1) {
    border: none;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  &__header:nth-child(5) {
    width: 14%;
  }
  &__header:nth-child(10) {
    width: 14%;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.orderingends {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  gap: 0.5rem;
  margin: 0.625rem 0;
  .stepone__subtitle {
    grid-area: 1 / 1;
    color: $contrast-color;
  }
  &__calendar {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $gray-30;
    border-radius: 8px;
    padding-left: 5px;

    .DateInput_input__focused {
      border-bottom: 2px solid $contrast-color !important;
    }
    .SingleDatePickerInput__withBorder {
      border: none !important;
    }

    .DateInput {
      width: 100% !important;
      display: flex !important;
      justify-content: center;
    }
    .DateInput_input {
      padding: 0 !important;
      font-size: 14px !important;
      text-align: center;
      width: 80% !important;
    }
    .DateInput_fang {
      top: 30px !important;
    }

    .SingleDatePicker_picker {
      top: 40px !important;
      left: -120.5312px !important;
    }
    .DayPickerKeyboardShortcuts_show {
      display: none;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: $contrast-color !important;
      border: 1px double $contrast-color !important;
    }
  }
  &__calendar-between {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid $gray-30;
    border-radius: 8px;
    padding-left: 5px;

    .DateInput_input__focused {
      border-bottom: 2px solid $contrast-color !important;
    }
    .SingleDatePickerInput__withBorder {
      border: none !important;
    }

    .DateInput {
      width: 100% !important;
      display: flex !important;
      justify-content: center;
    }
    .DateInput_input {
      padding: 0 !important;
      font-size: 14px !important;
      text-align: center;
      width: 80% !important;
    }
    .DateInput_fang {
      top: 30px !important;
    }
    .SingleDatePicker_picker {
      top: 40px !important;
      left: -120.5312px !important;
    }
    .DayPickerKeyboardShortcuts_show {
      display: none;
    }
    .CalendarDay__selected,
    .CalendarDay__selected:active,
    .CalendarDay__selected:hover {
      background: $contrast-color !important;
      border: 1px double $contrast-color !important;
    }
  }
  &__text {
    display: flex;
    align-items: center;
    color: $gray-90;
  }

  &__calendar-img {
    width: 30%;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    border-left: 1px solid $gray-30;
  }
}

.modalmagicaordering {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.headermodalmagicalordering {
  width: 635px;
  padding: 10px 0 10px 20px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  background-color: $gray-10;
  border-radius: 10px 10px 0 0;
}
.bodymodalmagicalordering {
  width: 635px;
  height: 280px;
  background-color: white;
  border-radius: 0 0 10px 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__exec {
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 75px;
  }
  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  &__buttons {
    width: 80%;
    margin-top: 100px;
    display: flex;
    justify-content: space-between;
  }
  &__cancel {
    width: 152px;
    padding: 10px 0;
    background-color: $gray-10;
    border-radius: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }
  &__confirm {
    width: 152px;
    padding: 10px 0;
    background-color: $contrast-color;
    border-radius: 18px;
    color: $cards-color;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }
  &__confirm-disabled {
    width: 152px;
    padding: 10px 0;
    background-color: $gray-10;
    border-radius: 18px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    cursor: pointer;
  }
  &__input {
    border: 1px solid $gray-30;
    text-align: center;
    padding: 4px 0;
    border-radius: 8px;
  }
}

.selectcategorymagicalordering {
  width: 97%;
  display: flex;
  flex-direction: column;
  position: relative;
  &__title {
    margin-bottom: 40px;
  }
  &__content {
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 40px;
  }
  &-icon-reestart {
    width: 29px;
  }
  &__reload {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 1rem;
    & * {
      cursor: pointer;
    }
  }
}

.cardcategorymagical {
  width: 27%;
  max-width: 270px;

  max-height: 344px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px 0;
  &__title {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    width: 90%;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__subtitle {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: $contrast-color;
    margin-bottom: 15px;
    cursor: pointer;
    padding: 0.5rem 0 0.5rem 0.5rem;
    .categorytextmagical__text {
      justify-content: space-between;
    }
  }
  &__icon-reestart {
    cursor: pointer;
  }
  &__options {
    width: 100%;
    max-height: 220px;
    overflow: auto;
    padding: 0.5rem 0 0.5rem 0.5rem;
  }
}

.cardsubcategorymagical {
  width: 100%;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  padding: 20px 0;
}

.categorytextmagical {
  min-height: 40px;
  display: grid;
  grid-template-columns: 1fr 50px;
  cursor: pointer;
  border-bottom: 1px solid $gray-30;

  &__text {
    width: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__category {
    width: 80%;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
    cursor: pointer;
  }
  &__img {
    background-image: url("../../images/magicalordeing/arrow-gray-right.png");
    background-position: center;
    background-repeat: no-repeat;
  }
  &__option {
    display: block;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-right: 25px;
  }
  &__checkbox {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  &__span {
    position: absolute;
    top: -8px;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: $gray-10;
    border: 1px solid $gray-90;
    border-bottom: 1px solid $gray-90;
    border-radius: 18px;
  }
  &__checkbox:checked ~ &__span {
    background-color: $cards-color;
    border: 1px solid $contrast-color;
  }
  &__span:after {
    content: "";
    position: absolute;
    display: none;
  }
  &__checkbox:checked ~ &__span:after {
    display: block;
  }
  &__option &__span:after {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: $contrast-color;
  }
}

.categorytextmagical:hover .categorytextmagical__category {
  color: $contrast-color;
}
.categorytextmagical:hover .categorytextmagical__img {
  background-image: url("../../images/magicalordeing/arrow-blue-right.png");
}

.spacesubcategomagical {
  width: 100%;
  max-width: 275px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  &__triangle {
    position: absolute;
    top: 22px;
    left: -8px;
    width: 17px;
    height: 17px;
    box-shadow: -1px 1px 0px rgba(0, 0, 0, 0.3);
    background-color: $gray-10;
    transform: rotate(45deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
